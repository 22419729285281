* {
  margin: 0;
}
@font-face {
  font-family: "Surpass Holiday";
  src: url("assets/fonts/surpass-holiday/Surpass\ Holiday\ -\ Free\ Trial.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.Surpass-Holiday {
  font-family: Surpass Holiday;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--primary-color, #218b95) !important;
}
.Roboto {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.shape {
  position: relative;
  width: 45vw;
}
.profileImage {
  width: 15vw;
  margin-inline-start: -5vw;
}
.width50 {
  height: 100% !important;
  width: 50vw;
}
.shapeContent {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.form-control:focus {
  box-shadow: none;
}
